import styled from '@emotion/styled';
import { Paper, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DocumentInsightsChatDialog } from './chat-dialog';
import { Close } from '../../icons/close';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { PILogo } from '../../../components/icons/pi-logo';
import { useChatbotService } from '../../../services/chatbot.service';
import { ChatbotUser, IChatbotConfig } from '../../../services/interfaces';

const ChatIcon = styled(Paper)(() => ({
    margin: 10, // You might not need this now
    position: 'fixed',
    cursor: 'pointer',
    padding: 8,
    display: 'flex',
    bottom: 10,
    right: 10,
    borderRadius: 90,
    zIndex: 9999,
}));
const ChatIconClose = styled(ChatIcon)(() => ({
    zIndex: 9999,
}));

interface IProps {
    userFileIds: string[];
}

declare const window: any;

export const DocumentInsightsChatBox = (props: IProps) => {
    const [open, setOpen] = useState(
        !!document.getElementById('photon-chat-widget-container')
    );
    const [user, setUser] = useState<ChatbotUser | null>(null);
    const [widgetSessionChatId, setWidgetSessionChatId] = useState<string>('');
    const [chatbotConfig, setChatbotConfig] = useState<IChatbotConfig | null>(
        null
    );
    const [configFetched, setConfigFetched] = useState(false);
    const [userPushed, setUserPushed] = useState(false);
    const { fetchChatbotConfig, setWidgetSessionUser } = useChatbotService();

    const refreshChatbotConfig = async () => {
        const _chatbotConfig = await fetchChatbotConfig();
        if (!chatbotConfig) {
            updateChatBotConfig(_chatbotConfig);
            setConfigFetched(true);
            if (!document.getElementById('photon-chat-widget-container')) {
                checkAutoOpenBehaviour(_chatbotConfig);
            }
        }
    };

    useEffect(() => {
        if (user && widgetSessionChatId && !userPushed) {
            setUserPushed(true);
            setWidgetSessionUser(user, widgetSessionChatId);
        }
    }, [user, widgetSessionChatId]);

    const checkAutoOpenBehaviour = (config) => {
        if (config.designConfig.config.behaviour.autoOpen.delay >= 0) {
            if (
                config.designConfig.config.behaviour.autoOpen.frequency ==
                    'EVERYTIME' ||
                (config.designConfig.config.behaviour.autoOpen.frequency ==
                    'ONCE_PER_VISIT' &&
                    !sessionStorage.getItem(config.widgetId))
            ) {
                triggerOpen(
                    config.widgetId,
                    config.designConfig.config.behaviour.autoOpen.delay
                );
            }
        }
    };

    const triggerOpen = (widgetId: string, delay: number) => {
        setTimeout(() => {
            sessionStorage.setItem(widgetId, 'true');
            setOpen(true);
        }, delay * 1000);
    };

    const updateChatBotConfig = (_chatbotConfig: IChatbotConfig) => {
        setChatbotConfig(_chatbotConfig);
    };

    const setSessionUser = (user: ChatbotUser | null) => {
        if (user == null) {
            setUser(null);
            setUserPushed(false);
            localStorage.removeItem('photon_user_info');
        }
        let ALLOWED_KEYS = ['id', 'email', 'metadata'];
        for (let key in user) {
            if (!ALLOWED_KEYS.includes(key)) {
                console.error(`Invalid key ${key}`);
                return;
            }
            if (key != 'metadata') {
                if (user[key]) {
                    user[key] = user[key].toString();
                }
                if (user[key] && user[key].length > 50) {
                    console.error(`${key} can be of max 50 characters`);
                }
            }
            if (key == 'metadata') {
                if (Object.keys(user.metadata).length > 5) {
                    console.error(`metadata can have max 5 properties`);
                    return;
                }
                for (let metadataKey in user.metadata) {
                    if (metadataKey.length > 20) {
                        console.error(
                            `metadata key'${metadataKey}' exceeds the max 20 characters limit allowed`
                        );
                        return;
                    }
                    user.metadata[metadataKey] =
                        user.metadata[metadataKey].toString();
                    if (user.metadata[metadataKey].length > 50) {
                        console.error(
                            `value for metadata key'${metadataKey}' exceeds the max 50 characters limit allowed`
                        );
                        return;
                    }
                }
            }
        }
        setUser(user);
        setUserPushed(false);
        localStorage.setItem('photon_user_info', JSON.stringify(user));
    };

    useEffect(() => {
        if (!window.photon) {
            window.photon = {};
        }
        window.photon.updateChatbotConfig = updateChatBotConfig.bind(this);
        window.photon.setUser = setSessionUser.bind(this);

        if (typeof window.photon.onLoad == 'function') {
            window.photon.onLoad();
        }
    });

    useEffect(() => {
        if (!configFetched && !chatbotConfig) {
            refreshChatbotConfig();
        }
    }, [configFetched]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return chatbotConfig ? (
        <Box
            sx={{
                '& *': {
                    fontFamily: `${
                        chatbotConfig.designConfig.config.appearance.font
                            .fontFamily || ''
                    } !important`,
                },
            }}
            className="chatbox"
        >
            <ChatIcon
                elevation={20}
                sx={{
                    opacity: open ? 0 : 1,
                    padding: '0px !important',
                    backgroundColor:
                        chatbotConfig.designConfig.config.appearance.colors
                            .primary,
                }}
                onClick={handleClickOpen}
            >
                <Box
                    sx={{
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        style={{
                            width: chatbotConfig.designConfig.config.appearance
                                .shape.icon
                                ? chatbotConfig.designConfig.config.appearance
                                      .shape.icon.size
                                : 55,
                            height: chatbotConfig.designConfig.config.appearance
                                .shape.icon
                                ? chatbotConfig.designConfig.config.appearance
                                      .shape.icon.size
                                : 55,
                            borderRadius: '50%',
                        }}
                        src={
                            chatbotConfig.designConfig.config.appearance.images
                                .chatBot
                        }
                    />
                </Box>
            </ChatIcon>

            {open && (
                <ChatIconClose
                    sx={{
                        backgroundColor:
                            chatbotConfig.designConfig.config.appearance.colors
                                .primary,
                    }}
                    onClick={() => setOpen(false)}
                >
                    <Close
                        color={
                            chatbotConfig.designConfig.config.appearance.colors
                                .text
                        }
                    />
                </ChatIconClose>
            )}
            <DocumentInsightsChatDialog
                open={open}
                setWidgetSessionChatId={setWidgetSessionChatId}
                widgetSessionChatId={widgetSessionChatId}
                chatbotConfig={chatbotConfig}
                userFileIds={props.userFileIds}
                handleClose={handleClose}
            />
        </Box>
    ) : null;
};
