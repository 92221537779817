import { useState } from 'preact/hooks';
import { Citation } from '../citation';
import { ChatMessage, IncomingMessageWrapper } from './chat-dialog';
import { ResponseSources } from './response-sources';
import { h } from 'preact';
import {
    IChatbotAppearance,
    IChatbotConfig,
    IMessage,
    IChatbotConfigData,
} from 'src/services/interfaces';
import { Box } from '@mui/material';

interface IProps {
    message: IMessage;
    chatbotConfig: IChatbotConfig;
    onCopyToClipBoardClick?: () => void;
    onUpVoteClick?: () => void;
    onDownVoteClick?: () => void;
    onBookmarkClick?: () => void;
}

export const IncomingMesage = (props: IProps) => {
    const [selectedCitationIndex, setSelectedCitationIndex] = useState<
        number | undefined
    >(undefined);
    const { message } = props;
    const handleCitationClick = (citationIndex: number) => {
        setSelectedCitationIndex(citationIndex);
    };
    return (
        <IncomingMessageWrapper>
            <ChatMessage
                sx={{
                    fontSize:
                        props.chatbotConfig.designConfig.config.appearance.font
                            .fontSize,
                }}
            >
                {message.data.answer && (
                    <Citation
                        onCitationClick={handleCitationClick}
                        text={message.data.answer}
                        disableCitation={!props.chatbotConfig.withCitations}
                    />
                )}
            </ChatMessage>
            {message.data.sources && props.chatbotConfig.withCitations && (
                <ResponseSources
                    onCopyToClipBoardClick={props.onCopyToClipBoardClick}
                    onBookmarkClick={props.onBookmarkClick}
                    messageId={props.message.messageID}
                    onUpVoteClick={props.onUpVoteClick}
                    onDownVoteClick={props.onDownVoteClick}
                    isBookmarked={message.isBookmarked}
                    vote={message.vote}
                    selectedCitationIndex={selectedCitationIndex}
                    sources={message.data.sources}
                />
            )}
        </IncomingMessageWrapper>
    );
};
